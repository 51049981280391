import React from "react";
import img1 from "../Images/whatsappButton.png";

export default function FixedWhatsapp() {
  return (
    <div className="fixedWhatsapp">
      <a href="https://wa.me/message/IYZ3JBRK7DDQE1">
        <img src={img1} loading="eager" />
      </a>
    </div>
  );
}
